import React from 'react'
import SbEditable from 'storyblok-react'

import Components from 'components/'
import Layout from 'components/common/Layout'

import config from '../../gatsby-config'

const sbConfigs = config.plugins.filter(item => {
	return item.resolve === 'gatsby-source-storyblok'
})
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}

const loadStoryblokBridge = function(cb) {
	const script = document.createElement('script')
	script.type = 'text/javascript'
	script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
	script.onload = cb
	document.getElementsByTagName('head')[0].appendChild(script)
}

const getParam = function(val) {
	var result = ''
	var tmp = []

	window.location.search
		.substr(1)
		.split('&')
		.forEach(function(item) {
			tmp = item.split('=')
			if (tmp[0] === val) {
				result = decodeURIComponent(tmp[1])
			}
		})

	return result
}

class StoryblokEntry extends React.Component {
	constructor(props) {
		super(props)
		this.state = { story: null }
	}

	componentDidMount() {
		loadStoryblokBridge(() => {
			this.initStoryblokEvents()
		})
	}

	loadStory(payload) {
		window.storyblok.get(
			{
				slug: getParam('path'),
				version: 'draft',
				resolve_relations: sbConfig.options.resolveRelations || [],
			},
			data => {
				this.setState({ story: data.story })
			},
		)
	}

	initStoryblokEvents() {
		this.loadStory({ storyId: getParam('path') })

		const sb = window.storyblok

		sb.on(['change', 'published'], payload => {
			this.loadStory(payload)
		})

		sb.on('input', payload => {
			if (this.state.story && payload.story.id === this.state.story.id) {
				payload.story.content = sb.addComments(payload.story.content, payload.story.id)
				this.setState({ story: payload.story })
			}
		})

		sb.pingEditor(() => {
			if (sb.inEditor) {
				sb.enterEditmode()
			}
		})
	}

	render() {
		if (this.state.story == null) {
			return (
				<div>
					<h3>Editor page</h3>
				</div>
			)
		}

		const { content } = this.state.story
		const { layout, seo } = content
		const isGlobalPage = content.component === 'global'

		return (
			<SbEditable content={content}>
				{isGlobalPage ? (
					<Layout headerData={content.header[0]} footerData={content.footer[0]}>
						<h3>Layout component</h3>
					</Layout>
				) : (
					<Layout
						headerData={layout.content && layout.content.header[0]}
						footerData={layout.content && layout.content.footer[0]}
						seo={seo}
					>
						{React.createElement(Components[content.component], {
							key: content._uid,
							blok: content,
						})}
					</Layout>
				)}
			</SbEditable>
		)
	}
}

export default StoryblokEntry
