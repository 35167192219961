require('dotenv').config({
	path: `.env.${process.env.NODE_ENV}`,
})

const siteUrl = process.env.URL || 'localhost:8000'

module.exports = {
	siteMetadata: {
		name: 'Gatsby Storyblok starter',
		author: 'Gracjan Opiela',
		siteUrl: siteUrl,
		title: 'Title of the Page',
		description: 'Short description of the website, max 255 characters',
		seo: {
			separator: '-',
			og: {
				fbAppId: '123',
				type: 'website',
				title: 'title',
				description: 'description',
				image: '/img/og-cover.png',
			},
			twitter: {
				creator: 'vertexventures',
				card: 'summary_large_image',
			},
		},
	},
	plugins: [
		{
			resolve: 'gatsby-source-storyblok',
			options: {
				accessToken: process.env.GATSBY_SB_GLOBAL,
				homeSlug: 'home',
				version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
				resolveRelations: ['global.layout', 'layout'],
			},
		},
		'gatsby-plugin-react-helmet',
		'gatsby-plugin-sharp',
		'gatsby-transformer-sharp',
		'gatsby-plugin-emotion',
		{
			resolve: 'gatsby-source-filesystem',
			options: {
				name: 'images',
				path: `${__dirname}/static/images`,
			},
		},
		{
			resolve: 'gatsby-plugin-svgr',
			options: {
				prettier: true, // use prettier to format JS code output (default)
				svgo: true, // use svgo to optimize SVGs (default)
				svgoConfig: {
					removeViewBox: true, // remove viewBox when possible (default)
					cleanupIDs: true, // remove unused IDs and minify remaining IDs (default)
				},
			},
		},
		{
			resolve: 'gatsby-plugin-typography',
			options: {
				pathToConfigModule: 'src/utils/typography',
			},
		},
		{
			resolve: 'gatsby-plugin-lodash',
			options: {
				disabledFeatures: [
					'shorthands',
					'cloning',
					'exotics',
					'currying',
					'guards',
					'metadata',
					'deburring',
					'unicode',
					'coercions',
					'flattening',
					'placeholders',
				],
			},
		},
	],
}
